import React, {useState} from 'react'
import './room.css'
import salaB from './salaa.jpg'

const RoomA = () => {
    const [isShown, setIsShown] = useState(true)
    return (
        <div className="room">
            <div className="room-content">
                {isShown && 
                <div className="room-title"><h3>Sala A</h3></div>}
                <div className="room-left">
                    <img 
                    onClick={() => setIsShown(!isShown)}
                    src={salaB}
                    alt="Sala prób większa próbówka music house wrocław"/>
                </div>
                {isShown && (
                <div className="room-right sala-b">
                    <div className="room-right-up">Powierzchnia 35 metrów kwadratowych, klimatyzowana, ogrzewana</div>
                    <div className="room-right-down">
                        <div className="labels">
                            <div className="label">Gitara 1:</div>
                            <div className="label">Gitara 2:</div>
                            <div className="label">Bas:</div>
                            <div className="label">Perkusja:</div>    
                            <div className="label">Przody:</div>
                            <div className="label">Mikser:</div>
                            <div className="label">Mikrofony:</div>
                        </div>
                        <div className="items">
                            <div className="item">Laboga Beast 30W + Paczka Laboga 212a</div>
                            <div className="item">Fender Champion 100 2x12</div>
                            <div className="item">Ashdown Mag EVO II 300 2x12</div>    
                            <div className="item">Mapex Pro M</div>
                            <div className="item">bB Technologies B-Hype 10</div>
                            <div className="item">Behringer X1204</div>
                            <div className="item">Shure SM 58</div>
                        </div>  
                    </div>
                </div>)}
            </div>
            
        </div>
    )
}

export default RoomA