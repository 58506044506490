import React, {useState} from 'react'
import './room.css'
import salaC from '../offer/studio4.jpg'

const RoomC = () => {
    const [isShown, setIsShown] = useState(true)

    return (
        <div className="room">
            <div className="room-content">
                {isShown && 
                <div className="room-title"><h3>Studio nagrań</h3></div>}
                <div className="room-left">
                    <img 
                    onClick={() => setIsShown(!isShown)}
                    src={salaC}
                    alt="studio nagrań próbówka music house wrocław"/>
                </div>
                {isShown && (
                <div className="room-right sala-c">
                    <div className="room-right-up">Powierzchnia 10 + 35 metrów (control room + live room), klimatyzowana, ogrzewana</div>
                    <div className="room-right-down">
                    <div className="labels">
                            <div className="label">Mikrofony:</div>
                            <div className="label">Monitory:</div>
                            <div className="label">Mixer:</div>
                            <div className="label">Wzmacniacz słuchawkowy:</div>    
                            <div className="label">Odsłuchy douszne:</div>
                            <div className="label">Pluginy:</div>
                            <div className="label">DAW:</div>
                        </div>
                        <div className="items">
                            <div className="item">AKG, Audio Technika, Shure</div>
                            <div className="item">Yamaha HS8</div>
                            <div className="item">Allen and heath SQ5</div>    
                            <div className="item">Behringer Powerplay HA6000</div>
                            <div className="item">Behringer powerplay P1</div>
                            <div className="item">Waves Horizon</div>
                            <div className="item">Reaper</div>
                        </div>    
                    </div>
                </div>)}
            </div>
            
        </div>
    )
}

export default RoomC