import React from 'react'
import './offer.css'
import RoomB from '../room/RoomA'
import RoomA from '../room/RoomB'
import RoomC from '../room/RoomC'
import Title from '../section-title/Title'

const Offer = () => {
    return (
        <div className="main-container">
            <link rel="canonical" href="https://www.sala-prob-wroclaw.pl/#offer" />
            <div className="offer" id="offer">
                <Title title="Próbówka Music House dysponuje dwoma salami prób oraz studiem"/>
            <RoomA /> 
            <RoomB/>
            <RoomC/>
            </div>
        </div>
    )
}

export default Offer
