import React from "react";
import './booking.css'
import { Link } from 'react-scroll'
import Title from "../section-title/Title";

const Booking = () => {

    
    return (
        <div className="booking bg" id="booking">
            <link rel="canonical" href="https://www.sala-prob-wroclaw.pl/#booking" />
            <Title title="Rezerwacje"/> 
            <div className="booking-main"> 
                <div className="booking-up">
                    <div className="booking-left">
                        <a target="_blank" rel="noreferrer" href="https://probowka.nakiedy.pl/"><div className="booking-button draw-border">ZAREZERWUJ</div></a>

                        <div className="booking-info">
                            <div className="tooltip">Uwaga!
                            <div className="tooltiptext">
                            System rezerwacyjny ma dwie ułomności:<br></br>
                            a) jeśli szukasz próby np. na 3h i nie ma wolnych miejsc, sprawdź krótszy czas, np. 2h (system sam nie podpowiada)<br></br>
                            b) jeśli planujesz próbę która zaczyna się jednego dnia, a kończy drugiego (np. poniedziałek 23.00 - wtorek 1.00) zarezerwuj godzinę w poniedziałek i godzinę we wtorek (system nie zazębia dni).<br></br>

                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="booking-right">
                        <div>
                        Rezerwując próbę akceptujesz regulamin (poniżej). <br></br>
                        Kod do wejścia dostaniesz sms-em na 15 min przed próbą. <br></br>
                        Płatność BLIKiem lub odliczoną gotówką na miejscu.<br></br>
                        Bezpłatne odwołanie próby: do 24h przed<br></br>
                        Od 24 do 12h przed: 50% kwoty<br></br>
                        Mniej niż 12h: 100% kwoty<br></br>
                        </div>
                        <div className="k-desktop">
                        Chcesz nagrać próbę? Skorzystać ze studia nagrań? Nagrać swój koncert? <Link to="kontakt" style={{cursor: 'pointer', color: "red"}} offset={-80} smooth={true}>Daj nam znać.</Link>
                        </div>
                        <div className="k-mobile">
                        Chcesz nagrać próbę? Skorzystać ze studia nagrań? Nagrać swój koncert? <Link to="kontakt" style={{cursor: 'pointer', color: "red"}} offset={-200} smooth={true}>Daj nam znać.</Link>    
                        </div>
                    </div>
                </div>
                <div className="booking-down">
                    <div className="rules-title">Regulamin</div>
                        <ol className="rules-points">
                            <li>Próby zaczynamy i kończymy punktualnie, tak żeby kolejny zespół mógł wejść o czasie.</li>
                            <li>Wynajmujący oznajmia, że sposób użytkowania sprzętu jest mu znany. </li>
                            <li>Za uszkodzenia sprzętu wynikające z niedbałości czy niewłaściwej eksploatacji odpowiada osoba wynajmująca. Zostanie ona obciążona kosztami naprawy sprzętu.</li>
                            <li>Na terenie sali obowiązuje całkowity zakaz palenia.</li>
                            <li>Nie kładziemy napojów na wzmacniacze i dbamy o porządek.</li>
                            <li>Niezwłocznie zgłaszamy uszkodzenia sprzętu, które zauważyliśmy.</li>
                        </ol>
                    </div>
            </div>

            

        </div>
    )
}

export default Booking
