import React from 'react'
import './title.css'

const Title = ({title}) => {
    return (
        <div className="title-main">
            <div className="title-content">
                <h2>{title}</h2>
            </div> 
            
        </div>
    )
}

export default Title
