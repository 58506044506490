import React, {useState, useEffect} from 'react'
import './navbar.css'
import logo from './logo1.png'
import { Link } from 'react-scroll'

const Navbar = () => {
    const [scrolled,setScrolled]=useState(false);
    const handleScroll=() => {
        const offset=window.scrollY;
        if(offset > 0 ){
          setScrolled(true);
        }
        else{
          setScrolled(false);
          console.log(offset)
        }
      }
    
      useEffect(() => {
        window.addEventListener('scroll',handleScroll)

      })
    let navbarClasses=['navbar'];
      if(scrolled){
        navbarClasses.push('scrolled');
      }
    return (
      <header className={navbarClasses.join(" ")}>
            <div className="navbar-left">
                <div className="titlee"><img src={logo} alt="Sala prób studio nagrań próbówka music house logo"/></div>
            </div>
            <div className="nav-desktop" id="menu">
                <Link to="head" smooth={true}><div className="nav-link">Home</div></Link>
                <Link to="offer" smooth={true} offset={-80}><div className="nav-link">Sale/Studio</div></Link>
                <Link to="pricing" smooth={true} offset={-80}><div className="nav-link">Cennik</div></Link>
                <Link to="booking"  smooth={true} offset={-80}><div className="nav-link">Rezerwacje</div></Link>
                <Link to="kontakt"  smooth={true} offset={-80}><div className="nav-link">Kontakt</div></Link>
            </div>
            <div className="nav-mobile" id="menu">
                <Link to="head"  smooth={true} offset={-200}><div className="nav-link">Home</div></Link>
                <Link to="offer"  smooth={true} offset={-200}><div className="nav-link">Sale/Studio</div></Link>
                <Link to="pricing"  smooth={true} offset={-200}><div className="nav-link">Cennik</div></Link>
                <Link to="booking"  smooth={true} offset={-200}><div className="nav-link">Rezerwacje</div></Link>
                <Link to="kontakt"  smooth={true} offset={-200}><div className="nav-link">Kontakt</div></Link>
            </div>
            <div className="navbar-right">
                  <a href="https://www.facebook.com/ProbowkaMusicHouse" class="social" rel="nofollow">
                    <svg class="icon" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"/></svg>
                  </a>
            </div>
        
        </header>
    )
}

export default Navbar
