import React, {useEffect, useState} from 'react'
import "./header.css"
import { Link } from 'react-scroll'


const Header = () => {
  const [landed,setLanded]=useState(false);
    const handleScroll=() => {
        const offset=window.scrollY;
        if(offset > 0 ){
          setLanded(true);
        }
        else{
          setLanded(false);
        }
      }
    
      useEffect(() => {
        window.addEventListener('scroll',handleScroll)

      })
    let landingClasses=['landing'];
      if(landed){
        landingClasses.push('landed');
      }
    return (
    <div className="header" id="head">
      <header className={landingClasses.join(" ")}>
        <div className="landing-main">
          <div className="landing-title"><h1>Witamy na stronie sali prób oraz studia nagrań<br></br> Próbówka Music House Wrocław.</h1></div>
          <div className="landing-content">
            <div className="landing-title-second">
              <p>Nasze dwie sale prób i studio nagrań znajdują się we Wrocławiu na ulicy Robotniczej 1a, brama 15.</p> 
            </div>
            <div className="landing-p">
              <p>Każda nasza sala prób to miejsce stworzone przez muzyków dla muzyków. Znamy wasze potrzeby. Miejsce jest klimatyzowane, ogrzewane, dostępne 24h na dobę, monitorowane, wyposażone w sprawdzony i zawsze działający sprzęt. Sala prób dysponuje również dużą ilością bezpłatnych miejsc parkingowych oraz toaletą. Ponadto wraz z salami prób połączone jest profesjonalne studio nagrań. 
              U nas bez problemu zarejestrujesz swoją próbę, nagrasz swój występ czy utrwalisz materiał na planowaną płytę.</p>
            </div>
            <div className="landing-p">
              <p>Sala prób, sala muzyczna, salka prób, studio nagrań, studio muzyczne, studio nagraniowe - jakkolwiek tego nie nazwiesz - znajdziesz to w Próbówce.</p>
            </div>
            <Link to="booking" offset={-80} smooth={true} style={{zIndex: 0}}><div className="landing-booking-button-desktop">REZERWACJA</div></Link>
            <Link to="booking" offset={-200} smooth={true} style={{zIndex: 0}}><div className="landing-booking-button-mobile">REZERWACJA</div></Link>
          </div>
        </div>
        </header>
        
          
        
    </div>
    )
}

export default Header
