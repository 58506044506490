import React from 'react'
import './footer.css'

const Footer = () => {
    return (
        
        <div className="footer">
            <div className="footer-content">
                <div className="footer-top">
                    <div className="footer-box l">
                        <div>Sale prób i studio nagrań</div>
                        <div>Próbówka Music House</div>
                        <div>ul. Robotnicza 1A</div>
                        <div> Wrocław</div>
                    </div>
                    <div className="footer-box r">
                        <div>Tel: 883 912 657</div>
                        <div>Tel: 888 316 939 </div>
                        <a href="https://www.facebook.com/ProbowkaMusicHouse" class="social" rel="nofollow">
                            <svg class="icon1" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"/></svg>
                        </a>
                    </div>
                </div>
            </div>
                <div className="footer-bottom">
                    <a href="http://www.linkedin.com/in/wojciechratajczakk" rel="nofollow">created by W. Ratajczak 2021</a>
                </div>
            </div>
    )
}

export default Footer
