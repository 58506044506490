import React from 'react'
import Header from './components/header/Header'
import Offer from './components/offer/Offer'
import Booking from './components/booking/Booking'
import Footer from './components/footer/Footer'
import Pricing from './components/pricing/Pricing'
import Contact from './components/contact/Contact'
import Navbar from './components/navbar/Navbar'

const App = () => {
  return (
    <div className="main">
      <Navbar/>
      <Header/>
      <Offer/>
      <Pricing/>
      <Booking/>
      <Contact/>
      <Footer />

    </div>
  )
}

export default App
