import React from 'react'
import './contact.css'
import marcin from './marcin.jpg'
import piotr from './piotr.jpg'
import robotnicza from './robotnicza.jpg'
import Title from '../section-title/Title'

const Contact = () => {
    return (
        <div className="contact" id="kontakt">
            <link rel="canonical" href="https://www.sala-prob-wroclaw.pl/#contact" />
            <Title title="Kontakt" />
            <div className="contact-content">
                <div className="contact-map">
                <iframe title="mapka" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2504.953370985949!2d17.012089015712576!3d51.1093204795717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fc20eb5257d33%3A0x41f1dcc598a24429!2sRobotnicza%201A%2C%2053-607%20Wroc%C5%82aw!5e0!3m2!1spl!2spl!4v1626467188168!5m2!1spl!2spl" width="350" height="250" style={{border:'2px solid grey'}} allowfullscreen="" loading="lazy"></iframe>
                </div>
                <div className="contact-image">
                    <img src={robotnicza} alt="Zdjęcie budynku, w którym jest Próbówka Music House Wrocław"></img>
                </div>
                <div className="contact-info">
                    <div id="d1">
                        Sale prób i studio nagrań<br></br>
                        Próbówka Music House<br></br>
                        ul. Robotnicza 1A, brama 15, Wrocław<br></br>
                    </div>
                    <div id="d2" style={{marginTop: '20px'}}>
                        Piotr Mączyński (rezerwacje, studio, sprawy techniczne): <br></br>
                        <b>883 912 657</b><br></br>
                        Marcin Dyś (rezerwacje): <br></br>
                        <b>888 316 939</b>
                    <div/>

                </div>
            </div>
            </div>
            <div className="about-us">
                <div className="about-us-title">Sale prób i studio nagrań Próbówka Music House prowadzą dla Was:</div>
                <div className="about-us-content">
                    <div className="hero">
                        <div className="hero-image"><img src={piotr} alt="Zdjęcie Piotra z Próbówka Music House Wrocław"></img> </div>
                        <div className="hero-text piotr"><b>Piotr "Mąka" Mączyński</b> - perkusista, z wykształcenia realizator dźwięku, 
                            uczęszczał także do Wrocławskiej Szkoły Jazzu i Muzyki Rozrywkowej (wydział instrumentalny, 
                            instrument główny: perkusja). Szlifów nabierał zarówno w Polsce (<a href="https://www.atmgrupa.pl/">ATM</a>) jak i za granicą w Londynie (<a href="https://hirefrequencies.co.uk/">Hire Frequencies</a>, <a href="https://www.ems-events.co.uk/">EMS Events).</a></div>
                        <div className="hero-contact"></div>
                    </div>
                    <div className="hero">
                        <div className="hero-image"><img src={marcin} alt="Zdjęcie Marcina z Próbówka Music House Wrocław"></img></div>
                        <div className="hero-text marcin"><b>Marcin "Dysiek" Dyś</b> - basista, prowadzi blog muzyczny <a href='http://facebook.com/ToDoCiebie'>"Posłuchaj to do ciebie"</a> oraz audycję radiową pod tym samym tytułem w <a href='https://radio.zameknadaje.pl'>Radio Zamek Nadaje</a>. Doktor nauk, autor książki "Oczami rockmana - Polska i świat
                            w tekstach polskich twórców rockowych lat '80 i '90 XX wieku" oraz szeregu innych artykułów dotyczących polskiej sceny alternatywnej.</div>
                        <div className="hero-contact"></div>
                    </div>
                </div>
            </div>

            
            
        </div>
    )
}

export default Contact
