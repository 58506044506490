import React from 'react'
import './pricing.css'
import Title from '../section-title/Title'

const Pricing = () => {
    return (
        <div className="pricing" id="pricing">
            <link rel="canonical" href="https://www.sala-prob-wroclaw.pl/#pricing" />
            <Title title="Cennik sali prób i studio nagrań Próbówka Music House Wrocław (niezależnie od wybranej sali)."/>
                <div className="pricing-content">
                    <div className="pricing-down">
                        <div className="pricing-left">
                            <div className="pricing-left-top">
                                <h3>Sale [rezerwacja on-line; płatność BLIKiem/gotówką na miejscu]</h3><br></br>
                                1 godzina = 35 zł <br></br>
                                2 godziny = 60 zł <br></br>
                                3 godziny = 80 zł <br></br>
                                Każda następna + 20 zł/h<br></br><br></br>
                                
                                W sali A dostępny jest statyw pod klawisze.<br></br>
                                Talerze - 10 zł / próba (w przypadku próby solo w cenie) <br></br>
                                Abonamenty: do uzgodnienia <br></br><br></br>
                                Rabaty: pn-pt od 6 do 15: za każdą godzinę 20 zł <br></br>
                            </div>
                            <div className="pricing-left-down">
                                <h3>Studio [rezerwacja telefoniczna; płatność BLIKiem/gotówką na miejscu]</h3><br></br>
                                Studio: 70 zł / h (z realizatorem) <br></br> 
                                Cały dzień (10h): 650 zł<br></br><br></br>
                                Mix i mastering od 100 zł utwór<br></br>
                                Przykładowe nagrania: <a href="https://soundcloud.com/piotr-maczynski"> https://soundcloud.com/piotr-maczynski</a>
                            </div>
                        </div>
                        <div className="pricing-right">
                            <div style={{color: "white"}}>
                                <h3>Inne [rezerwacja telefoniczna; płatność BLIKiem/gotówką na miejscu]</h3>
                            <br></br>
                            </div>
                            <div>
                            Nagrywanie próby (z sound checkiem przeprowadzonym przez realizatora): 45 zl / h (min 2h)<br></br>
                            </div>
                            <br></br>
                            <div>
                            Nagranie EPki:<br></br>
                            - jeden dzień nagrań zespołu 'na setkę' z realizatorem (10-12h; max. 16 ścieżek)<br></br>
                            - trzy utwory<br></br>
                            - dogrywka wokalu<br></br>
                            - miks<br></br>
                            - mastering<br></br>
                            - pliki video z kamery<br></br>

                            1500 zł
                            <br></br>
                            <br></br>
                            
                            Nagrywanie koncertu live w klubie: do uzgodnienia.<br></br>
                            <br></br>
                            Przykladowa realizacja:<br></br>
                            <a href="https://soundcloud.com/izolacja">https://soundcloud.com/izolacja</a><br></br><br></br>
                            </div>
                        </div>
                    </div>
                    <div className="pricing-up">
                    <h3>Potrzebujesz rachunku - nie ma sprawy - działamy legalnie.</h3>
                        </div>
                </div>
            </div>
    )
}

export default Pricing
